<div [formGroup]="form">
    <div class="mat-header-cell"><label [attr.for]="question.key">{{question.label}}</label></div>

    <div [ngSwitch]="question.type" class="wingsmed-dynamic-question">

        <div class="mat-cell" *ngSwitchCase="'textbox'">
            <mat-form-field>
                <input matInput (change)="modelChanged($event)" [formControlName]="question.key" [id]="question.key"
                    [type]="question.inputType" />
            </mat-form-field>
        </div>

        <div class="mat-cell" *ngSwitchCase="'checkbox'">
            <mat-form-field>
                <input matInput [hidden]="true">
                <mat-checkbox (change)="checkBoxModelChanged($event)" matInput [formControlName]="question.key"
                    [id]="question.key" value="{{question.defaultValue}}">{{question.label}}
                </mat-checkbox>
            </mat-form-field>
        </div>

        <div class="mat-cell" *ngSwitchCase="'largetextbox'">
            <mat-form-field>
                <textarea matInput (change)="modelChanged($event)" [formControlName]="question.key" [id]="question.key"
                    [type]="question.inputType"> </textarea>
            </mat-form-field>
        </div>

        <select [id]="question.key" *ngSwitchCase="'dropdown'" [formControlName]="question.key">
            <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
        </select>

        <div class="mat-cell" *ngSwitchCase="'selecttextbox'" [ngSwitch]="question.inputType">
            <div *ngSwitchCase="">
                <mat-radio-group fxLayout="row" name="radio_{{formName}}{{question.key}}"
                    formControlName="radio_{{formName}}{{question.key}}">
                    <mat-radio-button name="radio_{{formName}}{{question.key}}" (change)="setDefault($event)"
                        value="defaultValue">{{question.defaultValue}}</mat-radio-button>
                    <mat-radio-button name="radio_{{formName}}{{question.key}}" (change)="setOther($event)"
                        value="other" class="mat-radio-button-2">
                        <mat-form-field>
                            <input matInput placeholder="Inna" (change)="modelChanged($event)"
                                [formControl]="otherAnswer" name="otherAnswer" />
                        </mat-form-field>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngSwitchCase="'largetextbox'">
                <mat-radio-group fxLayout="column" name="radio_{{formName}}{{question.key}}"
                    formControlName="radio_{{formName}}{{question.key}}">
                    <mat-radio-button name="radio_{{formName}}{{question.key}}" (change)="setDefault($event)"
                        value="defaultValue">{{question.defaultValue}}</mat-radio-button><br>
                    <mat-radio-button name="radio_{{formName}}{{question.key}}" (change)="setOther($event)"
                        value="other" class="mat-radio-button-newline">
                        <mat-form-field style="width:500px;height:200px;">
                            <textarea style="width:500px;height:200px;" matInput placeholder="Inna"
                                (change)="modelChanged($event)" [formControl]="otherAnswer"
                                name="otherAnswer"> </textarea>
                        </mat-form-field>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="wingsmed-error-message wingsmed-center" *ngIf="!isValid">Pole {{ question.label | lowercase }} jest
            wymagane</div>
    </div>
</div>