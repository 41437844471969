import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseComponent } from "src/app/core/components/base.component";
import { ExaminationService } from "../services/examination.service";
import { GlobalService } from "src/app/core/services/global.service";
import { SignalrService } from "src/app/core/services/signalr.service";
import { SignalRCaptureRequest } from "src/app/core/models/signalr/capture-request";
import { BaseQuestion } from "src/app/shared/question-base";
import { CaptureRequest } from "src/app/core/models";
import { CaptureResponse } from "src/app/core/models/capture-response";
import { Subscription } from "rxjs";

@Component({
  selector: "wingsmed-examination-additional",
  templateUrl: "./additional.component.html",
})
export class ExaminationAdditionalComponent
  extends BaseComponent
  implements OnInit
{
  @Input() id: string;
  @Output() saveDynamicFormEvent = new EventEmitter<string>();
  step = -1;
  xrayQuestions: BaseQuestion[];
  xrayEvent = "xray";
  labQuestions: BaseQuestion[];
  labEvent = "lab";
  ecgQuestions: BaseQuestion[];
  ecgEvent = "ecg";
  performanceQuestions: BaseQuestion[];
  performanceEvent = "performance";
  chamberQuestions: BaseQuestion[];
  chamberEvent = "chamber";
  audioQuestions: BaseQuestion[];
  audioEvent = "audio";
  psychologistQuestions: BaseQuestion[];
  psychologistEvent = "psychologist";
  showLab: boolean = false;
  showEcg: boolean = false;
  showChamber: boolean = false;
  showPerformance: boolean = false;
  showAudio: boolean = false;
  showXray: boolean = false;
  showPsychologist: boolean = false;
  labStep: number = 0;
  ecgStep: number = 0;
  chamberStep: number = 0;
  performanceStep: number = 0;
  audioStep: number = 0;
  xrayStep: number = 0;
  psychologistStep: number = 0;
  stepCounter: number = 0;

  fileChecker: Subscription;
  captureResponse: CaptureResponse;
  
  constructor(
    private additionalExaminationService: ExaminationService,
    protected globalService: GlobalService,
    protected signalrService: SignalrService
  ) {
    super(globalService);
    this.signalrService.startConnection();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  loadData() {
    this.additionalExaminationService
      .getResource("/api/appointments/" + this.id + "/examinations/additional")
      .pipe()
      .subscribe((data) => {
        if (data.ecg != null) {
          this.ecgQuestions = data.ecg.questions;
          this.showEcg = true;
          this.ecgStep = this.stepCounter;
          this.stepCounter++;
        }
        if (data.lab != null) {
          this.labQuestions = data.lab.questions;
          this.showLab = true;
          this.labStep = this.stepCounter;
          this.stepCounter++;
        }
        if (data.performance != null) {
          this.performanceQuestions = data.performance.questions;
          this.showPerformance = true;
          this.performanceStep = this.stepCounter;
          this.stepCounter++;
        }
        if (data.chamber != null) {
          this.chamberQuestions = data.chamber.questions;
          this.showChamber = true;
          this.chamberStep = this.stepCounter;
          this.stepCounter++;
        }
        if (data.audio != null) {
          this.audioQuestions = data.audio.questions;
          this.showAudio = true;
          this.audioStep = this.stepCounter;
          this.stepCounter++;
        }
        if (data.xray != null) {
          this.xrayQuestions = data.xray.questions;
          this.showXray = true;
          this.xrayStep = this.stepCounter;
          this.stepCounter++;
        }
        if (data.psychologist != null) {
          this.psychologistQuestions = data.psychologist.questions;
          this.showPsychologist = true;
          this.psychologistStep = this.stepCounter;
        }

        this.loading = false;
      }, err => {
        this.error = true;
        this.errorMessage = err.error.translatedMessage;
      });
  }

  saveData(dynamicForm: string, uriPart: string) {
    this.additionalExaminationService
      .put(
        "/api/appointments/" + this.id + "/examinations/additional/" + uriPart,
        dynamicForm
      )
      .subscribe((data) => {
        this.globalService.showSuccess("Badanie zostało zapisane.");
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });

        this.saveDynamicFormEvent.emit("update");
      });
  }

  newFile(examination) {

    if (!this.signalrService.IsConnected) {
      alert("Błąd połączenia z aplikacją WingsCatch. Uruchom aplikację i odśwież stronę.");
      return;
    }

    let captureRequest: CaptureRequest = {
      type: "File",
      parentDocumentId: this.id,
      subType: examination
    } as CaptureRequest;

    this.additionalExaminationService
      .post("/api/captures", captureRequest)
      .pipe()
      .subscribe((res) => {
        this.captureResponse = res;
        if (this.fileChecker && !this.fileChecker.closed) {
          this.fileChecker.unsubscribe();
        }

        let captureDetails: { [key: string]: string; } = {};
        captureDetails["Uri"] = this.captureResponse.CaptureCallbackUri;

        let signalRCaptureRequest: SignalRCaptureRequest = {
          CaptureId: this.captureResponse.CaptureId,
          CaptureType: this.captureResponse.CaptureType,
          CaptureToken: this.captureResponse.CaptureToken,
          CaptureDetails: captureDetails
        } as SignalRCaptureRequest;

        this.signalrService.getCapture(signalRCaptureRequest);

       /* this.signatureChecker = timer(0, 2000)
          .pipe(
            map(() => {
              this.getSignature();
            })
          )
          .subscribe();*/
      });
  }

  onSubmit() {}

  ngOnDestroy(): void {}
}
