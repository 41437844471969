import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { BaseQuestion } from './question-base';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html'
})
export class DynamicFormQuestionComponent {
  @Input() question!: BaseQuestion;
  @Input() formName: string | null = "";
  @Input() form!: UntypedFormGroup;

  defaultChecked = false;
  otherChecked = false;

  public otherAnswer = new UntypedFormControl('');

  get isValid() { return this.form.controls[this.question.key].valid; }

  ngAfterContentInit(): void {

    this.form.controls["radio_" + this.formName + this.question.key] = new UntypedFormControl('');

    
    if (((this.question.value == null || this.question.value.trim() === "") && this.question.required === true) || (this.question.value === this.question.defaultValue)) {
      this.form.controls["radio_" + this.formName + this.question.key].setValue("defaultValue");
      this.updateValue(this.question.defaultValue);
    }
    else {
      this.form.controls["radio_" + this.formName + this.question.key].setValue('other');
      this.otherAnswer.setValue(this.question.value);
      this.updateValue(this.question.value);
    }
  }


  checkBoxModelChanged(event) {
    this.updateValue(event.checked);
  }

  modelChanged(event) {
    this.updateValue(event.target.value);
  }

  setDefault(event) {
    this.updateValue(this.question.defaultValue);
  }

  setOther(event) {
    this.updateValue(this.otherAnswer.value);
  }

  updateValue(value) {
    this.question.value = value;
    this.form.controls[this.question.key].setValue(value);
  }
}
