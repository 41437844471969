import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { ExaminationModule } from '../examination/examination.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportPrintoutComponent } from './components/printout.component';
import { ReportRoutingModule } from './report-routing.module';

@NgModule({
    declarations: [ReportPrintoutComponent],
    imports: [
        SharedModule,
        ReportRoutingModule,
        ExaminationModule,
        FormsModule,
        ReactiveFormsModule
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class ReportModule { }
