import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../core/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DynamicFormComponent } from '../shared/dynamic-form.component';
import { DynamicFormQuestionComponent } from '../shared/dynamic-form-question.component';


@NgModule({
  declarations: [
    DynamicFormComponent,
    DynamicFormQuestionComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    DynamicFormComponent,
    DynamicFormQuestionComponent
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
})
export class SharedModule { }
