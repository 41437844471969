import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/auth.guard';
import { PatientDetailsComponent } from './component/details.component';
import { PatientListComponent } from './component/list.component';

const routes: Routes = [
  {
    path: 'patients',
    component: PatientListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'patients/details',
    component: PatientDetailsComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientRoutingModule { }
