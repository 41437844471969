import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { ExaminationRoutingModule } from './examination-routing.module';
import { ExaminationAdditionalComponent } from './components/additional.component';
import { ExaminationMedicalComponent } from './components/medical.component';
import { ExaminationFinalComponent } from './components/final.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    ExaminationMedicalComponent,
    ExaminationFinalComponent,
    ExaminationAdditionalComponent
  ],
  exports: [
    ExaminationMedicalComponent,
    ExaminationFinalComponent,
    ExaminationAdditionalComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule, 
    ExaminationRoutingModule
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [DatePipe] 
})
export class ExaminationModule { }
