<div class="row wingsmed-row">
    <div class="col-md-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <mat-icon class="mr-2">accessibility_new</mat-icon>
                    <span class="page-title">Szczegóły pacjenta</span>
                    <div class="loading-shade" *ngIf="loading">
                        <mat-spinner *ngIf="loading" [diameter]="24"></mat-spinner>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="wingsapp-card-content" *ngIf="!loading">
                    <div class="mat-elevation-z4 wingsmed-patientsummary-box">
                        {{ dataSource?.fullName }}, {{ dataSource?.personalIdNumber }}, {{ dataSource?.factoryName }}
                        ({{ dataSource?.jobTypeName }})
                    </div>

                    <mat-tab-group class="mat-elevation-z4" #tabs>
                        <mat-tab label="Pacjent">
                            <table>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Nazwisko i imię:</td>
                                    <td class="mat-cell">{{ dataSource?.fullName }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Stanowisko:</td>
                                    <td class="mat-cell">{{ dataSource?.jobTypeName }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Zakład:</td>
                                    <td class="mat-cell">{{ dataSource?.factoryName }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">PESEL:</td>
                                    <td class="mat-cell">{{ dataSource?.personalIdNumber }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Data urodzenia:</td>
                                    <td class="mat-cell">{{ dataSource?.birthDate | date:'dd.MM.y' }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Miejsce urodzenia:</td>
                                    <td class="mat-cell">{{ dataSource?.birthPlace }}</td>
                                </tr>
                            </table>
                        </mat-tab>
                        <mat-tab label="Wizyty">
                            <div class="mat-elevation-z4">
                                <table mat-table [dataSource]="appointments" matSort matSortActive="date"
                                    matSortDirection="desc">
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by number"> Data </th>
                                        <td mat-cell *matCellDef="let row"> {{row.date | date:'dd.MM.yyyy'}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="examinationGroupName">
                                        <th mat-header-cell *matHeaderCellDef> Grupa badań </th>
                                        <td mat-cell *matCellDef="let row"> {{row.examinationGroupName}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="result">
                                        <th mat-header-cell *matHeaderCellDef> Wynik </th>
                                        <td mat-cell *matCellDef="let row"> {{row.result}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef> Akcja </th>
                                        <td mat-cell *matCellDef="let row">
                                            <button mat-raised-button color="accent"
                                                (click)="appointmentDetails(row) ">Szczegóły</button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[25, 50, 100] " showFirstLastButtons></mat-paginator>
                            </div>
                        </mat-tab>
                        <mat-tab label="Ostatnie badania">
                            <div class="mat-elevation-z4">
                                <table mat-table [dataSource]="examinationHistory" matSort matSortActive="date"
                                    matSortDirection="desc">

                                    <ng-container matColumnDef="examinationName">
                                        <th mat-header-cell *matHeaderCellDef> Nazwa badania </th>
                                        <td mat-cell *matCellDef="let row "> {{row.examinationName}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="examinationGroupName">
                                        <th mat-header-cell *matHeaderCellDef> Grupa badań </th>
                                        <td mat-cell *matCellDef="let row"> {{row.examinationGroupName}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            sortActionDescription="Sort by number"> Data </th>
                                        <td mat-cell *matCellDef="let row"> {{row.date | date:'dd.MM.yyyy'}}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="historyDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: historyDisplayedColumns;"></tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[25, 50, 100] " showFirstLastButtons></mat-paginator>
                            </div>
                        </mat-tab>
                        <mat-tab label="Zapisz na wizytę">
                            <form (ngSubmit)="saveNewAppointment()" [formGroup]="newAppointmentForm">

                                <div class="row">
                                    <div class="col">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Data badania</mat-label>
                                            <input matInput [matDatepicker]="dp2" formControlName="appointmentDate"
                                                disabled (dateChange)="dateEvent('change', $event)">
                                            <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                                            <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Grupa badania</mat-label>
                                            <mat-select matInput formControlName="examinationGroupId" required
                                                (selectionChange)="examinationEvent('change', $event)">
                                                <mat-option value="1">Kwalifikacyjne</mat-option>
                                                <mat-option value="2">Kontrolne</mat-option>
                                                <mat-option value="4">Okresowe</mat-option>
                                                <mat-option value="6">Profilaktyczne</mat-option>
                                                <mat-option value="7">Konsultacja</mat-option>
                                                <mat-option value="8">Kwalifikacyjne - powtórka 2</mat-option>
                                                <mat-option value="10">Ratownictwo</mat-option>
                                                <mat-option value="11">Okresowe - powtórka</mat-option>
                                                <mat-option value="13">Okresowe - powtórka II</mat-option>
                                                <mat-option value="14">Kwalifikacyjne - powtórka </mat-option>
                                                <mat-option value="15">kwalifikacyjne - powtórka II</mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="newAppointmentForm.get('examinationGroupId').hasError('required')">
                                                Pole jest wymagane
                                            </mat-error>

                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Numer dzienny</mat-label>
                                            <input matInput formControlName="dailyNumber">
                                        </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field appearance="fill">
                                            <input matInput [hidden]="true">
                                            <mat-radio-group aria-label="Wybierz grupę" matInput
                                                formControlName="dailyGroup" (change)="examinationEvent('change', $event)">
                                                <mat-radio-button value="1">Grupa 1&nbsp;&nbsp;</mat-radio-button>
                                                <mat-radio-button value="2">Grupa 2</mat-radio-button>
                                            </mat-radio-group>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">

                                        <div class="form-row">
                                            <mat-form-field appearance="fill">
                                                <input matInput [hidden]="true">
                                                <mat-checkbox matInput formControlName="isAudio">Badanie audiometryczne
                                                </mat-checkbox>
                                            </mat-form-field>
                                        </div>

                                        <div class="form-row">
                                            <mat-form-field appearance="fill">
                                                <input matInput [hidden]="true">
                                                <mat-checkbox matInput formControlName="isXray">Badanie RTG
                                                </mat-checkbox>
                                            </mat-form-field>
                                        </div>

                                        <div class="form-row">
                                            <mat-form-field appearance="fill">
                                                <input matInput [hidden]="true">
                                                <mat-checkbox matInput formControlName="isPsychologist">Badanie
                                                    psychologiczne
                                                </mat-checkbox>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-row">
                                            <mat-form-field appearance="fill">
                                                <input matInput [hidden]="true">
                                                <mat-checkbox matInput formControlName="isDiver">Nurek
                                                </mat-checkbox>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <button mat-raised-button color="primary" type="submit"
                                        [disabled]="!newAppointmentForm.valid">Zapisz</button>
                                </div>
                            </form>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>