import { NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ExaminationModule } from "../examination/examination.module";
import { PatientListComponent } from "./component/list.component";
import { PatientRoutingModule } from "./patient-routing.module";
import { AppointmentDetailsComponent } from "../appointment/components/details.component";
import { PatientDetailsComponent } from "./component/details.component";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";


@NgModule({
    declarations: [PatientListComponent, PatientDetailsComponent],
    imports: [
        SharedModule,
        PatientRoutingModule,
        ExaminationModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class PatientModule {}
