import { APP_INITIALIZER, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppointmentModule } from './appointment/appointment.module';
import { DeviceModule } from './device/device.module';
import { ExaminationModule } from './examination/examination.module';
import { CoreModule } from './core/core.module';
import { BASE_URL } from './core/core.token';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { PatientModule } from './patient/patient.module';
import { MatSortModule } from '@angular/material/sort';
import { SignalrService } from './core/services/signalr.service';
import { ErrorInterceptor } from './core/services/error-interceptor';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ReportModule } from './report/report.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    CoreModule,
    AppointmentModule,
    PatientModule,
    DeviceModule,
    ReportModule,
    ExaminationModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatSortModule
  ],
  exports: [
    BrowserModule, ReactiveFormsModule, MatSortModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: BASE_URL, useValue: environment.apiUrl },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pl-PL' },
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
