<div class="row wingsmed-row">
    <div class="col-md-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <mat-icon class="mr-2">print</mat-icon>
                    <span class="page-title">Raporty</span>
                    <div class="loading-shade" *ngIf="loading">
                        <mat-spinner *ngIf="loading" [diameter]="24"></mat-spinner>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <br>
            <mat-accordion class="wingsmed-expansion-headers-align">
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Orzeczenia
                        </mat-panel-title>
                        <mat-panel-description>
                            Druk orzeczeń z danego dnia
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <form (ngSubmit)="decisionsReport()" [formGroup]="decisionsReportForm">
                        <div class="form-row">
                            <mat-form-field appearance="fill">
                                <mat-label>Data raportu</mat-label>
                                <input matInput [matDatepicker]="dp2" formControlName="reportDate" disabled>
                                <mat-datepicker-toggle matSuffix [for]="dp2">
                                </mat-datepicker-toggle>
                                <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="form-row">
                            <mat-form-field appearance="fill">
                                <input matInput [hidden]="true">
                                <mat-radio-group aria-label="Wybierz grupę" matInput formControlName="decisionsDailyGroup">
                                    <mat-radio-button value="1">Grupa 1&nbsp;&nbsp;</mat-radio-button>
                                    <mat-radio-button value="2">Grupa 2</mat-radio-button>
                                    <mat-radio-button value="">Wszyscy</mat-radio-button>
                                </mat-radio-group>
                            </mat-form-field>
                        </div>

                        <div class="form-row">
                            <button mat-raised-button color="primary" type="submit">Generuj</button>
                        </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion class="wingsmed-expansion-headers-align">
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Karty badań
                        </mat-panel-title>
                        <mat-panel-description>
                            Druk kart badań z danego dnia
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <form (ngSubmit)="examinationsReport()" [formGroup]="examinationsReportForm">
                        <div class="form-row">
                            <mat-form-field appearance="fill">
                                <mat-label>Data raportu</mat-label>
                                <input matInput [matDatepicker]="dp3" formControlName="examinationDate" disabled>
                                <mat-datepicker-toggle matSuffix [for]="dp3">
                                </mat-datepicker-toggle>
                                <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="form-row">
                            <mat-form-field appearance="fill">
                                <input matInput [hidden]="true">
                                <mat-radio-group aria-label="Wybierz grupę" matInput formControlName="examinationsDailyGroup">
                                    <mat-radio-button value="1">Grupa 1&nbsp;&nbsp;</mat-radio-button>
                                    <mat-radio-button value="2">Grupa 2</mat-radio-button>
                                    <mat-radio-button value="">Wszyscy</mat-radio-button>
                                </mat-radio-group>
                            </mat-form-field>
                        </div>

                        <div class="form-row">
                            <button mat-raised-button color="primary" type="submit">Generuj</button>
                        </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card>
    </div>
</div>