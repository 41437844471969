import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { AuthGuard } from '../auth.guard';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private translatedErrors = new Map<number, string>();

    constructor(private authGuard: AuthGuard) { 
        this.translatedErrors.set(100100012, "Orzeczenie nie zostało wystawione");
        this.translatedErrors.set(100100010, "Brak podpisu pacjenta");
        this.translatedErrors.set(900000001, "Brak uprawnień");
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                location.reload();
            }
            console.log(`Backend returned code ${err.status}: ${err.error.message} (${err.error.code})`);

            let translatedErrorMessage: string;

            if (this.translatedErrors.has(err.error.code)) {
                translatedErrorMessage = this.translatedErrors.get(err.error.code);
            }
            else if (err.status === 403)
            {
                translatedErrorMessage = "Brak uprawnień";
            }
            else
            {
                translatedErrorMessage = err.error.message;
            }

            err.error.translatedMessage = translatedErrorMessage;

            return throwError(err);
        }))
    }
}