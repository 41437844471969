import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base.component';
import { ExaminationService } from '../services/examination.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'wingsmed-examination-final',
  templateUrl: './final.component.html'
})
export class ExaminationFinalComponent extends BaseComponent implements OnInit {

  @Input() patient: any;
  dataSource;
  form!: UntypedFormGroup;
  result: Boolean = false;

  constructor(private finalExaminationService: ExaminationService,
    protected globalService: GlobalService,
    private datePipe: DatePipe) {
    super(globalService);
    this.loading = true;
  }

  loadData() {
    this.finalExaminationService.getResource('/api/appointments/' + this.id + '/examinations/final').pipe(
    ).subscribe(data => {
      this.dataSource = data;
      const controls: any = {};
      controls['examinationDate'] = new UntypedFormControl(this.dataSource.examinationDate, Validators.required);
      controls['nextDate'] = new UntypedFormControl(this.dataSource.nextDate);
      let defaultNextDateText = "w wyznaczonym terminie";
      let after6months = "za 6 miesięcy";
      let after12months = "za 12 miesięcy";
      if (this.dataSource.nextDateText != null) {
        defaultNextDateText = this.dataSource.nextDateText;
      }
      controls['nextDateReason'] = new UntypedFormControl(defaultNextDateText);
      controls['result'] = new UntypedFormControl(this.dataSource.result, Validators.required);

      if (this.dataSource.result = "no") {
        if (this.dataSource.nextDateReason != null) {
          controls['resultOK'] = new UntypedFormControl(this.dataSource.nextDateReason);
        }
        else {
          controls['resultOK'] = new UntypedFormControl("date");
        }
      }

      this.onResultChange(this.dataSource.result);
      this.onResultOKChange(this.dataSource.result);
      this.form = new UntypedFormGroup(controls);
      this.loading = false;
    }, err => {
      this.error = true;
      this.errorMessage = err.error.translatedMessage;
    });
  }

  onSubmit() {
    this.dataSource.examinationDate = this.datePipe.transform(this.form.controls['examinationDate'].value, 'yyyy-MM-dd');
    this.dataSource.result = this.form.controls['result'].value;
    this.dataSource.resultOK = this.form.controls['resultOK'].value;
    if (this.dataSource.result == "no") {
      if (this.dataSource.resultOK == "date") {
        this.dataSource.nextDate = this.datePipe.transform(this.form.controls['nextDate'].value, 'yyyy-MM-dd');
        this.dataSource.nextDateReason = null;
      }
      else {
        this.dataSource.nextDate = null;
        this.dataSource.nextDateReason = this.dataSource.resultOK;
      }
    }
    else {
      this.dataSource.nextDate = null;
      this.dataSource.nextDateReason = this.form.controls['nextDateReason'].value;
    }

    this.finalExaminationService.put('/api/appointments/' + this.id + '/examinations/final', JSON.stringify(this.dataSource))
      .subscribe(data => {
        this.globalService.showSuccess("Orzeczenie zostało zapisane.");
      },
        (err) => { this.globalService.showError(err.error.translatedMessage); });
  }

  onResultChange(value) {
    if (value === 'no') {
      this.result = true;
    }
    else if (value === 'existing') {
      this.result = false;
    }
  }

  onResultOKChange(value) {
    if (value === 'no') {
      this.result = true;
    }
    else if (value === 'existing') {
      this.result = false;
    }
  }

  print() {
    this.finalExaminationService
      .downloadPDF("/api/appointments/" + this.id + "/decisionpdfblob")
      .pipe()
      .subscribe((res) => {
        const byteArray = new Uint8Array(
          atob(res.blob)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      });
  }
}
