<div class="row wingsmed-row">
    <div class="col-md-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <mat-icon class="mr-2">people</mat-icon>
                    <span class="page-title">Pacjenci</span>
                    <div class="loading-shade" *ngIf="loading">
                        <mat-spinner *ngIf="loading" [diameter]="24"></mat-spinner>
                    </div>
                </mat-card-title>


            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>

                <div class="wingsapp-card-content">
                    <!--*ngIf="!loading"-->
                    <mat-form-field>
                        <input matInput name="filter" placeholder="Filtr" #filter />
                    </mat-form-field>
                    <div class="mat-elevation-z4">
                        <table mat-table [dataSource]="dataSource" matSort>

                            <!-- Name Column -->
                            <ng-container matColumnDef="patientName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nazwisko i imię </th>
                                <td mat-cell *matCellDef="let row"> {{row.fullName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="personalIdNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> PESEL </th>
                                <td mat-cell *matCellDef="let row"> {{row.personalIdNumber}} </td>
                            </ng-container>

                            <ng-container matColumnDef="patientRecordNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nr kartoteki </th>
                                <td mat-cell *matCellDef="let row"> {{row.externalId}} </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef> Akcja </th>
                                <td mat-cell *matCellDef="let row">
                                    <button mat-raised-button color="accent" (click)="patientDetails(row)">Szczegóły</button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns "></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
                        </table>

                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>