import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseComponent } from "src/app/core/components/base.component";
import { ExaminationService } from "../services/examination.service";
import { GlobalService } from "src/app/core/services/global.service";
import { BaseQuestion } from "src/app/shared/question-base";

@Component({
  selector: "wingsmed-examination-medical",
  templateUrl: "./medical.component.html",
})
export class ExaminationMedicalComponent
  extends BaseComponent
  implements OnInit {
  @Input() id: string;
  @Input() refreshData: boolean = false;
  @Output() dataRefreshed = new EventEmitter<string>();
  questions: BaseQuestion[];

  constructor(
    private medicalExaminationService: ExaminationService,
    protected globalService: GlobalService
  ) {
    super(globalService);
  }

  loadData() {

    var retrievedObject = localStorage.getItem('medical_examination_' + this.id);

    if (retrievedObject != null) {
      this.questions = JSON.parse(retrievedObject);
      this.globalService.showSuccess("Wczytano dane tymczasowe.");
      this.loading = false;
      return;
    }

    this.medicalExaminationService
      .getResource("/api/appointments/" + this.id + "/examinations/medical")
      .pipe()
      .subscribe((data) => {
        this.questions = data.questions;
        this.loading = false;
      }, err => {
        this.error = true;
        this.errorMessage = err.error.translatedMessage;
      });
  }

  saveData(dynamicForm: string) {

    if (!this.globalService.checkToken(false)) {
      localStorage.setItem('medical_examination_' + this.id, JSON.stringify(this.questions));
      this.globalService.checkToken(true);
    }

    this.medicalExaminationService
      .put(
        "/api/appointments/" + this.id + "/examinations/medical",
        dynamicForm
      )
      .subscribe((data) => {
        this.globalService.showSuccess("Badanie zostało zapisane.");
        localStorage.removeItem('medical_examination_' + this.id);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      });
  }

  ngOnChanges() {
    if (this.refreshData) {
      this.loadData();
      this.dataRefreshed.emit("update");
    }
  }

  ngOnDestroy(): void { }
}
