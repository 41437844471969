// Add here your keycloak setup infos
const keycloakConfig = {
  url: 'https://login.wingsapp.eu',
  realm: 'med.wingsapp.eu',
  clientId: 'wingsmed-authentication',
  updateMinValidity: 5
};

export const environment = {
  production: false,
  apiUrl: 'https://medapi.wingsapp.eu',
  keycloakConfig
};
