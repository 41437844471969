import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { BaseComponent } from "src/app/core/components/base.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { GlobalService } from "src/app/core/services/global.service";
import { PatientService } from "src/app/patient/services/patient.service";
import { Appointment, NewAppointment } from "src/app/core/models";
import { ExaminationHistory } from "src/app/core/models/examination-history";
import { DatePipe, formatDate } from "@angular/common";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { MatTabGroup } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { forkJoin } from "rxjs/internal/observable/forkJoin";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";

@Component({
  selector: "wingsmed-patient-details",
  templateUrl: "./details.component.html",
})
export class PatientDetailsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  dataSource;
  displayedColumns: string[] = [
    "date",
    "examinationGroupName",
    "result",
    "actions",
  ];

  historyDisplayedColumns: string[] = [
    "examinationName",
    "examinationGroupName",
    "date",
  ];
  
  filterValue = "";
  appointments: MatTableDataSource<Appointment>;
  examinationHistory: MatTableDataSource<ExaminationHistory>;
  newAppointmentForm!: UntypedFormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  
  constructor(
    private patientService: PatientService,
    protected globalService: GlobalService,
    private datePipe: DatePipe,
    private router: Router
  ) {
    super(globalService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  appointmentDetails(row: Appointment) {
    let route = "/appointments/details";
    this.router.navigate([route], { queryParams: { id: row.id } });
  }
  loadData() {

    var todaysDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    forkJoin(
      {
        patient: this.patientService.getResource("/api/patients/" + this.id + "/details"),
        nextnumber: this.patientService.getResource("/api/dailynumbers/" + todaysDate + "/0/next")
      }
    )
    .subscribe(({patient, nextnumber}) => {
      this.dataSource = patient;
      this.appointments = new MatTableDataSource(patient.appointments);
      this.appointments.sort = this.sort;
      this.examinationHistory = new MatTableDataSource(patient.examinationHistory);
      this.examinationHistory.sort = this.sort;

      this.newAppointmentForm = new UntypedFormGroup({
        examinationGroupId: new UntypedFormControl ('4', Validators.required),
        appointmentDate: new UntypedFormControl (todaysDate, Validators.required),
        isAudio: new UntypedFormControl ('1'),
        isXray: new UntypedFormControl (''),
        isPsychologist: new UntypedFormControl (''),
        isDiver: new UntypedFormControl (''),
        dailyNumber: new UntypedFormControl (nextnumber.number),
        dailyGroup: new UntypedFormControl(nextnumber.group)
      });

      this.loading = false;
    });
  }
  
  saveNewAppointment() {
    var newAppointment:NewAppointment = {
      patientId: this.dataSource.id,
       examinationGroupId: this.newAppointmentForm.controls['examinationGroupId'].value,
       appointmentDate: this.datePipe.transform(this.newAppointmentForm.controls['appointmentDate'].value, 'yyyy-MM-dd'),
       isAudio: this.newAppointmentForm.controls['isAudio'].value,
       isPsychologist: this.newAppointmentForm.controls['isPsychologist'].value,
       isXray: this.newAppointmentForm.controls['isXray'].value,
       isDiver: this.newAppointmentForm.controls['isDiver'].value,
       dailyNumber: this.newAppointmentForm.controls['dailyNumber'].value,
       dailyGroup: this.newAppointmentForm.controls['dailyGroup'].value
      }

    this.patientService
    .post(
      "/api/appointments",
      newAppointment
    )
    .subscribe((data) => {
      this.globalService.showSuccess("Wizyta została zapisana.");
      this.loading = true;
      this.loadData();
      this.tabGroup.selectedIndex = 1;
    }, (err) => { this.globalService.showError(err.error.translatedMessage); });
  }

  dateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    var changedDate = formatDate(event.value, 'yyyy-MM-dd', 'en');

    this.patientService.getResource("/api/dailynumbers/" + changedDate + "/0/next")
    .subscribe((data) => {
      this.newAppointmentForm.get('dailyNumber').setValue(data.number);
      this.newAppointmentForm.get('dailyGroup').setValue(data.group);
    });
  }

  examinationEvent(type: string, event: Event) {
    var currrentDate = this.newAppointmentForm.get('appointmentDate').getRawValue();
    var dailyGroup = this.newAppointmentForm.get('dailyGroup').getRawValue();

    this.patientService.getResource("/api/dailynumbers/" + formatDate(currrentDate, 'yyyy-MM-dd', 'en') + "/" + dailyGroup +"/next")
    .subscribe((data) => {
      this.newAppointmentForm.get('dailyNumber').setValue(data.number);
      this.newAppointmentForm.get('dailyGroup').setValue(data.group);
    });
  }

  ngOnDestroy(): void {}
}
