<div class="">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">

        <div *ngFor="let question of questions; let i = index; first as isFirst" class="form-row">
            <h2 *ngIf="isFirst" class="wingsmed-examination-h2">{{question.group}}</h2>
            <h3 *ngIf="isFirst" class="wingsmed-examination-h3">{{question.subgroup}}</h3>

            <h2 *ngIf="(!isFirst && question.group != questions[i-1].group)" class="wingsmed-examination-h2">{{question.group}}</h2>
            <h3 *ngIf="(!isFirst && question.subgroup != questions[i-1].subgroup)" class="wingsmed-examination-h3">{{question.subgroup}}</h3>
            <app-question [question]="question" [form]="form" [formName]="formName"></app-question>
        </div>

        <div class="form-row">
            <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Zapisz</button>
        </div>
    </form>
</div>