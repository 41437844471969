<div class="row wingsmed-row">
    <div class="col-md-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <mat-icon class="mr-2">tablet_android</mat-icon>
                    <span class="page-title">Zarządzanie tabletem</span>
                    <div class="loading-shade" *ngIf="loading">
                        <mat-spinner *ngIf="loading" [diameter]="24"></mat-spinner>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="wingsapp-card-content">
                    <mat-tab-group *ngIf="loading === false" class="mat-elevation-z4">
                        <mat-tab label="Informacje">
                            <table>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">ID tabletu:</td>
                                    <td class="mat-cell">{{ dataSource?.deviceId }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Czas połączenia:</td>
                                    <td class="mat-cell">{{ dataSource?.updatedAt | date:'dd.MM.y HH:mm:ss' }}</td>
                                </tr>
                            </table>
                        </mat-tab>

                        <mat-tab label="Nowe Połączenie">

                            <p>Aby połączyć tablet z twoim kontem, naciśnij przycisk Generuj nowy kod. <br>Na tablecie wybierz USTAWIENIA, a następnie przycisk POŁACZ Z APLIKACJA.
                                <br> Następnie zeskanuj kod QR, który się pojawił. Tablet zostanie automatycznie skonfigurowany.</p>

                            <div class="wingsmed-center">
                                <button mat-raised-button color="accent" (click)="newDevicePairing()">Generuj nowy kod</button>
                                <br>
                                <qr-code value="{{ qrCode }}" size="300" errorCorrectionLevel="M"></qr-code>
                            </div>
                        </mat-tab>


                    </mat-tab-group>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>