import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Exception } from "../models/exception";
import { KeycloakService } from "keycloak-angular";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public keycloakAngular: KeycloakService
  ) {}

  public checkToken(reload: boolean)
  {
    const keycloakAuth = this.keycloakAngular.getKeycloakInstance();
    if (keycloakAuth.isTokenExpired())
    {
      if (reload)
      {
        this.showError("Sesja została zakończona. Strona zostanie załadowana ponownie.");
        setTimeout( () => {  }, 1500 );
        location.reload();
      }
      return false; 
    }

    return true;
  } 

  public showError(message: string) {
    this.snackBar.open(message, null, {
      duration: 3000,
      verticalPosition: "top",
      panelClass: "errorSnackBar",
    });
  }

  public showSuccess(message: string) {
    this.snackBar.open(message, null, {
      duration: 3000,
      verticalPosition: "top",
      panelClass: "successSnackBar",
    });
  }

  public showWarning(message: string) {
    this.snackBar.open(message, null, {
      duration: 3000,
      verticalPosition: "top",
      panelClass: "warningSnackBar",
    });
  }

  public getParameter(param: string) {}
}
