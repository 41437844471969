import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { AppointmentRoutingModule } from './appointment-routing.module';
import { AppointmentListComponent } from './components/list.component';
import { AppointmentUpcomingListComponent } from './components/upcoming-list.component';
import { AppointmentPastListComponent } from './components/past-list.component';
import { AppointmentTodayListComponent } from './components/today-list.component';
import { AppointmentDetailsComponent } from './components/details.component';
import { ExaminationModule } from '../examination/examination.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [AppointmentListComponent, AppointmentTodayListComponent, AppointmentDetailsComponent, AppointmentUpcomingListComponent, AppointmentPastListComponent],
    imports: [
        SharedModule,
        AppointmentRoutingModule,
        ExaminationModule,
        FormsModule,
        ReactiveFormsModule
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AppointmentModule { }
