import {
  Component,
  ViewChild,
  ViewContainerRef,
  OnInit,
} from "@angular/core";
import { BaseComponent } from "src/app/core/components/base.component";
import { AppointmentService } from "src/app/appointment/services/appointment.service";
import { GlobalService } from "src/app/core/services/global.service";
import { SignalrService } from "src/app/core/services/signalr.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DatePipe, formatDate } from "@angular/common";

@Component({
  selector: "wingsmed-report-printout",
  templateUrl: "./printout.component.html",
})
export class ReportPrintoutComponent
  extends BaseComponent
  implements OnInit {
  dataSource;
  examination = "";
  decisionsReportForm!: UntypedFormGroup;
  examinationsReportForm!: UntypedFormGroup;

  @ViewChild("medicalexamination", { read: ViewContainerRef })
  entry: ViewContainerRef;
  constructor(
    private appointmentService: AppointmentService,
    protected globalService: GlobalService,
    private datePipe: DatePipe
  ) {
    super(globalService);

    this.decisionsReportForm = new UntypedFormGroup({
      reportDate: new UntypedFormControl(formatDate(new Date(), 'yyyy-MM-dd', 'en'), Validators.required),
      decisionsDailyGroup: new UntypedFormControl("1")
    });

    this.examinationsReportForm = new UntypedFormGroup({
      examinationDate: new UntypedFormControl(formatDate(new Date(), 'yyyy-MM-dd', 'en'), Validators.required),
      examinationsDailyGroup: new UntypedFormControl("1")
    });
  }

  loadData() {
    console.log("in loading");

    this.loading = false;
  }

  decisionsReport() {

    var reportDate = this.datePipe.transform(this.decisionsReportForm.controls['reportDate'].value, 'yyyy-MM-dd');
    var group = this.decisionsReportForm.controls['decisionsDailyGroup'].value;

    this.appointmentService
      .downloadPDF("/api/reports/decisions/" + reportDate + "?group=" + group)
      .pipe()
      .subscribe((res) => {
        const byteArray = new Uint8Array(
          atob(res.blob)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      },
        (err) => { this.globalService.showError(err.error.translatedMessage); });
  }

  examinationsReport() {

    var reportDate = this.datePipe.transform(this.examinationsReportForm.controls['examinationDate'].value, 'yyyy-MM-dd');
    var group = this.examinationsReportForm.controls['examinationsDailyGroup'].value;

    this.appointmentService
      .downloadPDFFile("/api/reports/examinations/file/" + reportDate + "?group=" + group)
      .pipe()
      .subscribe((res) => {
        var file = new Blob([res.body], {type: 'application/pdf'});
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, "_blank");
        
      },
        (err) => { this.globalService.showError(err.error.translatedMessage); });
  }
}
