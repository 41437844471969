<div class="row wingsmed-row">
    <div class="col-md-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <mat-icon class="mr-2">healing icon</mat-icon>
                    <span class="page-title">Szczegóły wizyty</span>
                    <div class="loading-shade" *ngIf="loading">
                        <mat-spinner *ngIf="loading" [diameter]="24"></mat-spinner>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="wingsapp-card-content" *ngIf="!loading">
                    <mat-tab-group class="mat-elevation-z4" (selectedTabChange)="onTabChanged($event);">
                        <mat-tab label="Wizyta">
                            <table>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Numer dzienny:</td>
                                    <td class="mat-cell">{{ dataSource?.dailyNumber }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Numer kartoteki:</td>
                                    <td class="mat-cell">{{ dataSource?.patient.externalId }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">ID:</td>
                                    <td class="mat-cell">{{ dataSource?.id }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Data:</td>
                                    <td class="mat-cell">{{ dataSource?.date | date:'dd.MM.y' }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Grupa badań:</td>
                                    <td class="mat-cell">{{ dataSource?.examinationGroup?.fullName }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Podpis pacjenta:</td>
                                    <td class="mat-cell"><img class="wingsmed-patient-signature" src={{signatureUrl}} />
                                    </td>
                                    <td class="mat-cell">
                                        <button mat-raised-button color="accent" (click)="newSignature()">Nowy
                                            Podpis</button>
                                    </td>
                                </tr>
                                <tr class="mat-row" style="vertical-align: baseline;">
                                    <td class="mat-header-cell">Dokumenty:</td>
                                    <td class="mat-cell">
                                        <div class="wingsmed-documents-list">
                                            <div *ngFor="let newMedicalDocument of newMedicalDocuments; let i = index; first as isFirst"
                                                class="wingsmed-documents-list-item">
                                                <a target="_blank"  href="#" (click)="showDocument(newMedicalDocument.token)">{{newMedicalDocument.filesName}}</a> 
                                            </div>
                                        </div>
                                        <div class="wingsmed-documents-list">

                                            <div *ngFor="let medicalDocument of medicalDocuments; let i = index; first as isFirst"
                                                class="wingsmed-documents-list-item">
                                                <a target="_blank" href="#" (click)="showDocument(medicalDocument.token)">{{medicalDocument.filesName}}</a> 
                                            </div>

                                        </div>

                                    </td>
                                    <td class="mat-cell">
                                        <button mat-raised-button color="accent" (click)="newDocument()">Skanuj
                                            dokumenty</button><br /><br />
                                        <button mat-raised-button color="accent" (click)="newFile()">Dodaj plik</button>
                                    </td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Wydruki:</td>
                                    <td class="mat-cell">
                                        <div class="wingsmed-tab-actions">
                                            <button mat-raised-button color="accent" (click)="print()">Karta
                                                Badania</button>
                                            <button mat-raised-button color="accent"
                                                (click)="printFinal()">Orzeczenie</button>
                                            <button mat-raised-button color="accent"
                                                (click)="printFinalDuplicate()">Duplikat Orzeczenia</button>
                                        </div>
                                    </td>
                                </tr>

                            </table>

                            <mat-accordion class="wingsmed-expansion-headers-align">
                                <mat-expansion-panel hideToggle>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Edycja
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            Zmiana szczegółów wizyty
                                            <mat-icon>edit</mat-icon>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>

                                    <form (ngSubmit)="saveUpdateAppointment()" [formGroup]="updateAppointmentForm">
                                        <div class="row">
                                            <div class="col">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>Nazwisko i imię</mat-label>
                                                    <input matInput formControlName="patientFullName">
                                                </mat-form-field>
                                            </div>

                                            <div class="col">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>Zakład</mat-label>
                                                    <input matInput formControlName="factoryFullName">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        
                                        <div class="row">
                                            <div class="col">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>Data badania</mat-label>
                                                    <input matInput [matDatepicker]="dp2"
                                                        formControlName="appointmentDate" disabled>
                                                    <mat-datepicker-toggle matSuffix [for]="dp2">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                                                </mat-form-field>
                                            </div>

                                            <div class="col">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>Grupa badania</mat-label>
                                                    <mat-select matInput formControlName="examinationGroupId" required>
                                                        <mat-option value="1">Kwalifikacyjne</mat-option>
                                                        <mat-option value="2">Kontrolne</mat-option>
                                                        <mat-option value="4">Okresowe</mat-option>
                                                        <mat-option value="6">Profilaktyczne</mat-option>
                                                        <mat-option value="7">Konsultacja</mat-option>
                                                        <mat-option value="8">Kwalifikacyjne - powtórka 2</mat-option>
                                                        <mat-option value="10">Ratownictwo</mat-option>
                                                        <mat-option value="11">Okresowe - powtórka</mat-option>
                                                        <mat-option value="13">Okresowe - powtórka II</mat-option>
                                                        <mat-option value="14">Kwalifikacyjne - powtórka </mat-option>
                                                        <mat-option value="15">kwalifikacyjne - powtórka II</mat-option>
                                                    </mat-select>        
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>Numer dzienny</mat-label>
                                                    <input matInput formControlName="dailyNumber">
                                                </mat-form-field>
                                            </div>

                                            <div class="col">
                                                <mat-form-field appearance="fill">
                                                    <input matInput [hidden]="true">
                                                    <mat-radio-group aria-label="Wybierz grupę" matInput
                                                        formControlName="dailyGroup">
                                                        <mat-radio-button value="1">Grupa 1&nbsp;&nbsp;
                                                        </mat-radio-button>
                                                        <mat-radio-button value="2">Grupa 2</mat-radio-button>
                                                    </mat-radio-group>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col">
                                                <div class="form-row">
                                                    <mat-form-field appearance="fill">
                                                        <input matInput [hidden]="true">
                                                        <mat-checkbox matInput formControlName="isAudio">Badanie
                                                            audiometryczne
                                                        </mat-checkbox>
                                                    </mat-form-field>
                                                </div>
                                                <div class="form-row">
                                                    <mat-form-field appearance="fill">
                                                        <input matInput [hidden]="true">
                                                        <mat-checkbox matInput formControlName="isXray">Badanie RTG
                                                        </mat-checkbox>
                                                    </mat-form-field>
                                                </div>
                                                <div class="form-row">
                                                    <mat-form-field appearance="fill">
                                                        <input matInput [hidden]="true">
                                                        <mat-checkbox matInput formControlName="isPsychologist">Badanie
                                                            psychologiczne</mat-checkbox>
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            <div class="col">
                                                <div class="form-row">
                                                    <mat-form-field appearance="fill">
                                                        <input matInput [hidden]="true">
                                                        <mat-checkbox matInput formControlName="isDiver">Nurek
                                                        </mat-checkbox>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <button mat-raised-button color="primary" type="submit"
                                                [disabled]="!updateAppointmentForm.valid">Zapisz</button>
                                        </div>
                                    </form>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </mat-tab>
                        <mat-tab label="Pacjent">
                            <table>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Nazwisko i imię:</td>
                                    <td class="mat-cell">{{ dataSource?.patient.fullName }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Numer kartoteki:</td>
                                    <td class="mat-cell">{{ dataSource?.patient.externalId }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Stanowisko:</td>
                                    <td class="mat-cell">{{ dataSource?.patient.jobTypeName }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Zakład:</td>
                                    <td class="mat-cell">{{ dataSource?.patient.factoryName }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">PESEL:</td>
                                    <td class="mat-cell">{{ dataSource?.patient.personalIdNumber }}</td>
                                </tr>
                                <tr class="mat-row">
                                    <td class="mat-header-cell">Data urodzenia:</td>
                                    <td class="mat-cell">{{ dataSource?.patient.birthDate | date:'dd.MM.y' }}</td>
                                </tr>

                            </table>
                        </mat-tab>
                        <mat-tab label="Badania">
                            <wingsmed-examination-additional (saveDynamicFormEvent)="reloadData()" [id]="id">
                            </wingsmed-examination-additional>
                        </mat-tab>
                        <mat-tab label="Badanie lekarskie">
                            <wingsmed-examination-medical [id]="id" [refreshData]="refreshData"
                                (dataRefreshed)="dataRefreshed()"></wingsmed-examination-medical>
                        </mat-tab>
                        <mat-tab label="Orzeczenie">
                            <wingsmed-examination-final [patient]="dataSource?.patient"></wingsmed-examination-final>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>