import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { Exception } from "../models/exception";
import { SignalRCaptureRequest } from "../models/signalr/capture-request";

declare var $: any;

@Injectable({
  providedIn: "root",
})
export class SignalrService {

  public IsConnected: Boolean = false;
  private hubConnection: signalR.HubConnection;

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl("http://localhost:7900/capture")
      .build();
    this.hubConnection
      .start()
      .then(() => { this.IsConnected = true; console.log("Connection started"); })
      .catch(error => { this.IsConnected = false; this.errorHandler(error); });
  };

  public getCapture(captureRequest: SignalRCaptureRequest) {

    let encodedRequest = btoa(encodeURIComponent(JSON.stringify(captureRequest)).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16))
    }));

    try {
      if (this.hubConnection) {
        this.hubConnection.invoke('GetCapture', encodedRequest);
      }
      else {
        this.hubConnection
          .start().then(() => this.hubConnection.invoke('GetCapture', encodedRequest))
          .catch(this.errorHandler);
      }
    }
    catch (e) {
      alert("błąd");
    }
  }

  public errorHandler(err): void {
    console.log(err);
  }
}
