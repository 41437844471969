
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BASE_URL } from 'src/app/core/core.token';
import { CoreHttpService } from 'src/app/core/services/core-http.service';
import { GlobalService } from 'src/app/core/services/global.service';


@Injectable({
  providedIn: 'root'
})
export class DeviceService extends CoreHttpService {

  constructor(
    protected http: HttpClient,
    @Inject(BASE_URL) protected baseUrl: string
    ) {
    super(http, baseUrl);
  }

  
}
