import { Component, OnInit } from '@angular/core';
import { QrCodeModule } from 'ng-qrcode';
import { BaseComponent } from 'src/app/core/components/base.component';
import { GlobalService } from 'src/app/core/services/global.service';
import { DeviceService } from '../services/device.service';

@Component({
  selector: 'wingsmed-device-maintenance',
  templateUrl: './maintenance.component.html'
})
export class DeviceMaintenanceComponent extends BaseComponent implements OnInit {

  qrCode = "";
  dataSource;

  constructor(protected deviceService: DeviceService, protected globalService: GlobalService) {
    super(globalService);
  }

  loadData() {
    console.log('in loading');
    this.deviceService.getResourceById('/api/devicepair',"").pipe(
    ).subscribe(data => {
      this.dataSource = data;
      this.loading = false;
    });
  }

  newDevicePairing() {
    console.log('in loading');
    this.deviceService.getResourceById('/api/devicepair/create',"").pipe(
    ).subscribe(data => {
      
        this.qrCode = data;
      });
  }

}
