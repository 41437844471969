import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppointmentListComponent } from './components/list.component';
import { AuthGuard } from '../core/auth.guard';
import { AppointmentDetailsComponent } from './components/details.component';
import { AppointmentTodayListComponent } from './components/today-list.component';
import { AppointmentUpcomingListComponent } from './components/upcoming-list.component';
import { AppointmentPastListComponent } from './components/past-list.component';


const routes: Routes = [
  {
    path: 'appointments',
    component: AppointmentListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'todayappointments',
    component: AppointmentTodayListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'upcomingappointments',
    component: AppointmentUpcomingListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pastappointments',
    component: AppointmentPastListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'appointments/details',
    component: AppointmentDetailsComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppointmentRoutingModule { }
