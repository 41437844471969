<div class="container">
    <mat-toolbar color="primary">
        <button (click)="drawer.toggle()" mat-icon-button>
            <mat-icon>menu</mat-icon>
        </button>
        <img>
        <span class="example-spacer"></span>
        <button mat-icon-button (click)="doLogout()" matTooltip="Log Out">
            <mat-icon>logout</mat-icon>
        </button>
    </mat-toolbar>

    <mat-drawer-container class="wingsapp-container">
        <mat-drawer class="wingsmed-sidenav" #drawer mode="side" opened>
            <mat-list class="wingsmed-mat-list">
                <mat-list-item>
                    <button mat-stroked-button routerLink="todayappointments" routerLinkActive="active"
                        class="wingsmed-menu-button">
                        <mat-icon>today</mat-icon>
                        <span>Dzisiejsze wizyty</span>
                    </button>
                </mat-list-item>
                <mat-list-item>
                    <button mat-stroked-button routerLink="upcomingappointments" routerLinkActive="active"
                        class="wingsmed-menu-button">
                        <mat-icon>update</mat-icon>
                        <span>Nadchodzące wizyty</span>
                    </button>
                </mat-list-item>
                <mat-list-item>
                    <button mat-stroked-button routerLink="pastappointments" routerLinkActive="active"
                        class="wingsmed-menu-button">
                        <mat-icon>history</mat-icon>
                        <span>Poprzednie wizyty</span>
                    </button>
                </mat-list-item>
                <mat-list-item>
                    <button mat-stroked-button routerLink="patients" routerLinkActive="active"
                        class="wingsmed-menu-button">
                        <mat-icon>people</mat-icon>
                        <span>Pacjenci</span>
                    </button>
                </mat-list-item>
                <mat-list-item>
                    <button mat-stroked-button routerLink="reports" routerLinkActive="active"
                        class="wingsmed-menu-button">
                        <mat-icon>print</mat-icon>
                        <span>Raporty</span>
                    </button>
                </mat-list-item>
                <div style="position: absolute; left:0; bottom: 10px; right:0; text-align: center;">
                    <mat-list-item>
                        <a mat-stroked-button href="/assets/pdf/Instrukcja_Obslugi.pdf" target="_blank"
                            class="wingsmed-menu-button">
                            <mat-icon>help</mat-icon>
                            <span>Pomoc</span>
                        </a>
                    </mat-list-item>
                    <p>Wersja: 1.8.0</p>
                </div>
            </mat-list>
        </mat-drawer>
        <mat-drawer-content>
            <router-outlet></router-outlet>
        </mat-drawer-content>
    </mat-drawer-container>
</div>