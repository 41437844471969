import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { BASE_URL } from "src/app/core/core.token";
import { CoreHttpService } from "src/app/core/services/core-http.service";
import { Observable } from "rxjs/internal/Observable";
import { Patient } from "src/app/core/models/patient";

@Injectable({
  providedIn: "root",
})
export class PatientService extends CoreHttpService {
  constructor(
    protected http: HttpClient,
    @Inject(BASE_URL) protected baseUrl: string
  ) {
    super(http, baseUrl);
  }

  findPatients(filter ="", pageNumber = 1, pageSize = 100): Observable<Patient[]> {
    return this.http
      .get(this.baseUrl + "/api/patients", {
        params: new HttpParams()
          .set("filter", filter)
          .set("pageNumber", pageNumber.toString())
          .set("pageSize", pageSize.toString()),
      })
      .pipe(
        map((res) => res["objects"]),
        catchError(this.handleError)
      );
  }
}
