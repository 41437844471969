import {
  Component,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/core/components/base.component";
import { AppointmentService } from "../services/appointment.service";
import { Subscription, timer } from "rxjs";
import { map } from "rxjs/operators";
import { CaptureRequest, NewAppointment } from "src/app/core/models";
import { GlobalService } from "src/app/core/services/global.service";
import { SignalrService } from "src/app/core/services/signalr.service";
import { SignalRCaptureRequest } from "src/app/core/models/signalr/capture-request";
import { CaptureResponse } from "src/app/core/models/capture-response";
import { ExaminationService } from "src/app/examination/services/examination.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DatePipe, formatDate } from "@angular/common";

@Component({
  selector: "wingsmed-appointment-details",
  templateUrl: "./details.component.html",
})
export class AppointmentDetailsComponent
  extends BaseComponent
  implements OnInit {
  dataSource;
  signatureChecker: Subscription;
  signatureUrl;
  documentChecker: Subscription;
  documentUrl;
  medicalDocuments;
  newMedicalDocuments: Array<any> = new Array;
  captureResponse: CaptureResponse;
  examination = "";
  updateAppointmentForm!: UntypedFormGroup;
  refreshData: boolean = false;

  @ViewChild("medicalexamination", { read: ViewContainerRef })
  entry: ViewContainerRef;
  constructor(
    private appointmentService: AppointmentService,
    private finalExaminationService: ExaminationService,
    protected globalService: GlobalService,
    protected signalrService: SignalrService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    super(globalService);
    this.signalrService.startConnection();
  }

  loadData() {
    console.log("in loading");
    this.appointmentService
      .getResourceById("/api/appointments", this.id)
      .pipe()
      .subscribe((data) => {
        this.dataSource = data;
        this.signatureUrl = data.patientSignature;
        this.medicalDocuments = data.medicalFiles;
        this.updateAppointmentForm = new UntypedFormGroup({
          patientFullName: new UntypedFormControl(this.dataSource.patientFullName),
          factoryFullName: new UntypedFormControl(this.dataSource.factoryFullName),
          appointmentDate: new UntypedFormControl(formatDate(this.dataSource.date, 'yyyy-MM-dd', 'en'), Validators.required),
          isAudio: new UntypedFormControl(this.dataSource.isAudio),
          isXray: new UntypedFormControl(this.dataSource.isXray),
          isPsychologist: new UntypedFormControl(this.dataSource.isPsychologist),
          isDiver: new UntypedFormControl(this.dataSource.isDiver),
          dailyNumber: new UntypedFormControl(this.dataSource.dailyNumber),
          dailyGroup: new UntypedFormControl(this.dataSource.dailyGroup)
        });
        this.loading = false;
      });
  }

  reloadData() {
    this.refreshData = true;
  }

  dataRefreshed() {
    this.refreshData = false;
  }

  getSignature() {
    this.appointmentService
      .getResource("/api/captures/" + this.captureResponse.CaptureToken + "/signature")
      .pipe()
      .subscribe((data) => {
        if (data != null) {
          this.signatureUrl = data;
          this.signatureChecker.unsubscribe();
          console.log("Received signature: " + data);
        }
      });
  }

  getDocument(captureType) {
    this.appointmentService
      .getResource("/api/captures/" + this.captureResponse.CaptureToken + "/document")
      .pipe()
      .subscribe((data) => {
        if (data != null) {

          for (var _i = 0; _i < data.length; _i++) {
            const v = data[_i];  // OK
            this.newMedicalDocuments.push(v);
          }

          
          this.documentChecker.unsubscribe();
          console.log("Received documents: " + data);
        }
      });
  }

  showDocument(token) {
    this.appointmentService
      .downloadPDF("/api/file/" + token + "/downloadblob")
      .pipe()
      .subscribe((res) => {
        const byteArray = new Uint8Array(
          atob(res.blob)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: res.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      },
        (err) => { this.globalService.showError(err.error.translatedMessage); });

    return false;
  }

  print() {
    this.appointmentService
      .downloadPDF("/api/appointments/" + this.id + "/pdfblob")
      .pipe()
      .subscribe((res) => {
        const byteArray = new Uint8Array(
          atob(res.blob)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      },
        (err) => { this.globalService.showError(err.error.translatedMessage); });
  }

  printFinal() {
    this.finalExaminationService
      .downloadPDF("/api/appointments/" + this.id + "/decisionpdfblob")
      .pipe()
      .subscribe((res) => {
        const byteArray = new Uint8Array(
          atob(res.blob)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      },
        (err) => { this.globalService.showError(err.error.translatedMessage); });
  }

  printFinalDuplicate() {
    this.finalExaminationService
      .downloadPDF("/api/appointments/" + this.id + "/decisionpdfblob/duplicate")
      .pipe()
      .subscribe((res) => {
        const byteArray = new Uint8Array(
          atob(res.blob)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      },
        (err) => { this.globalService.showError(err.error.translatedMessage); });
  }

  newSignature() {

    if (!this.signalrService.IsConnected) {
      alert("Błąd połączenia z aplikacją WingsCatch. Uruchom aplikację i odśwież stronę.");
      return;
    }

    let captureRequest: CaptureRequest = {
      type: "Signature",
      parentDocumentId: this.id
    } as CaptureRequest;

    this.appointmentService
      .post("/api/captures", captureRequest)
      .pipe()
      .subscribe((res) => {
        this.captureResponse = res;
        if (this.signatureChecker && !this.signatureChecker.closed) {
          this.signatureChecker.unsubscribe();
        }

        let captureDetails: { [key: string]: string; } = {};
        captureDetails["Name"] = this.dataSource.patient.fullName;
        captureDetails["Uri"] = this.captureResponse.CaptureCallbackUri;

        let signalRCaptureRequest: SignalRCaptureRequest = {
          CaptureId: this.captureResponse.CaptureId,
          CaptureType: this.captureResponse.CaptureType,
          CaptureToken: this.captureResponse.CaptureToken,
          CaptureDetails: captureDetails
        } as SignalRCaptureRequest;

        this.signalrService.getCapture(signalRCaptureRequest);

        this.signatureChecker = timer(0, 2000)
          .pipe(
            map(() => {
              this.getSignature();
            })
          )
          .subscribe();
      });
  }

  async newDocument() {
    var result = this.createCapture("Scan");
  }

  newFile() {
    var result = this.createCapture("File");
  }


  createCapture(captureType) {

    if (!this.signalrService.IsConnected) {
      alert("Błąd połączenia z aplikacją WingsCatch. Uruchom aplikację i odśwież stronę.");
      return;
    }

    var todaysDate = new Date();
    let captureRequest: CaptureRequest = {
      type: captureType,
      parentDocumentId: this.id,
      filesName: "Dokumentacja Medyczna " + formatDate(todaysDate, 'dd-MM-yyyy HH:mm', 'en'),
      subType: "medical"
    } as CaptureRequest;

    this.appointmentService
      .post("/api/captures", captureRequest)
      .pipe()
      .subscribe((res) => {
        this.captureResponse = res;
        if (this.documentChecker && !this.documentChecker.closed) {
          this.documentChecker.unsubscribe();
        }

        let captureDetails: { [key: string]: string; } = {};
        captureDetails["Name"] = this.dataSource.patient.fullName;
        captureDetails["Uri"] = this.captureResponse.CaptureCallbackUri;
        captureDetails["FilenamePrefix"] = "Dokumentacja_" + this.dataSource.patient.personalIdNumber + "_";

        let signalRCaptureRequest: SignalRCaptureRequest = {
          CaptureId: this.captureResponse.CaptureId,
          CaptureType: this.captureResponse.CaptureType,
          CaptureToken: this.captureResponse.CaptureToken,
          CaptureDetails: captureDetails
        } as SignalRCaptureRequest;

        var result = this.signalrService.getCapture(signalRCaptureRequest);

        console.log(result);

        if (captureType == "Scan")
        {
          this.documentChecker = timer(0, 2000)
          .pipe(
            map(() => {
              this.getDocument(captureType);
            })
          )
          .subscribe();
        }
        
      });

  }


  saveUpdateAppointment() {
    var updateAppointment: NewAppointment = {
      appointmentDate: this.datePipe.transform(this.updateAppointmentForm.controls['appointmentDate'].value, 'yyyy-MM-dd'),
      fullName: this.updateAppointmentForm.controls['patientFullName'].value,
      factoryName: this.updateAppointmentForm.controls['factoryFullName'].value,
      isAudio: this.updateAppointmentForm.controls['isAudio'].value,
      isDiver: this.updateAppointmentForm.controls['isDiver'].value,
      isPsychologist: this.updateAppointmentForm.controls['isPsychologist'].value,
      isXray: this.updateAppointmentForm.controls['isXray'].value,
      dailyNumber: this.updateAppointmentForm.controls['dailyNumber'].value,
      dailyGroup: this.updateAppointmentForm.controls['dailyGroup'].value
    }

    this.appointmentService
      .put(
        "/api/appointments/" + this.dataSource.id,
        updateAppointment
      )
      .subscribe((data) => {
        this.globalService.showSuccess("Wizyta została zaaktualizowana.");
        this.loading = true;
        this.loadData();
      }, (err) => { this.globalService.showError(err.error.translatedMessage); });
  }

  onTabChanged($event): void {
    this.globalService.checkToken(true);
  }

  ngOnDestroy(): void {
    if (this.signatureChecker && !this.signatureChecker.closed) {
      this.signatureChecker.unsubscribe();
    }
    if (this.documentChecker && !this.documentChecker.closed) {
      this.documentChecker.unsubscribe();
    }
  }
}
