import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PatientService } from '../services/patient.service';
import { Router } from '@angular/router';
import { Patient } from 'src/app/core/models';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  templateUrl: './list.component.html'
})
export class PatientListComponent implements OnInit {

  loading = true;
  displayedColumns: string[] = ['patientName', 'personalIdNumber', 'patientRecordNumber', 'actions'];

  filterValue = '';
  dataSource: MatTableDataSource<Patient>;
  @ViewChild('filter') filter: ElementRef;
  constructor(
    private patientService: PatientService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loading = true;

    let patientFilter = localStorage.getItem('patientFilter');
    if (patientFilter != null)
    {
      this.filterValue =localStorage.getItem('patientFilter');
    }

    this.loadData(this.filterValue);
  }

  patientDetails(row: Patient) {
    let route = '/patients/details';
    this.router.navigate([route], { queryParams: { id: row.id } });
  }

  ngAfterViewInit() {
    fromEvent(this.filter.nativeElement,'keyup')
        .pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => {
                this.loadData(this.filter.nativeElement.value);
            })
        )
        .subscribe();
  }

  loadData(filter) {
    this.loading = true;
    this.patientService.findPatients(filter).pipe(
    ).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.loading = false;
    });
  }

  ngOnDestroy()
  {
    localStorage.setItem('patientFilter', this.filter.nativeElement.value)
  }
  
}