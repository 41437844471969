<div class="wingsmed-forbidden-error" *ngIf="error === true">
    {{errorMessage}}
</div>
<div class="row wingsmed-row" *ngIf="loading === false">
    <mat-accordion class="wingsmed-expansion-headers-align">
        <mat-expansion-panel *ngIf="showEcg" [expanded]="step === ecgStep" (opened)="setStep(ecgStep)" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    EKG + Spirometr
                </mat-panel-title>
                <mat-panel-description>
                    Badanie elektrokardiograficzne i spirometria
                    <mat-icon>favorite_border</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-dynamic-form (saveDynamicFormEvent)="saveData($event, ecgEvent)" [questions]="ecgQuestions" [formName]="ecgEvent"></app-dynamic-form>
            <mat-action-row>
                <button mat-raised-button color="accent" (click)="newFile('ecg')">Dodaj plik</button>
                <button mat-raised-button color="accent" (click)="nextStep()">Dalej</button>
            </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="showLab" [expanded]="step === labStep" (opened)="setStep(labStep)" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Laboratorium
                </mat-panel-title>
                <mat-panel-description>
                    Badanie morfologii, OB i badanie ogólne moczu
                    <mat-icon>colorize</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-dynamic-form (saveDynamicFormEvent)="saveData($event, labEvent)" [questions]="labQuestions" [formName]="labEvent"></app-dynamic-form>

            <mat-action-row>
                <button mat-raised-button color="accent" (click)="nextStep()">Dalej</button>
            </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="showPerformance" [expanded]="step === performanceStep" (opened)="setStep(performanceStep)" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Próba wydolnościowa
                </mat-panel-title>
                <mat-panel-description>
                    Badanie wydolności fizycznej
                    <mat-icon>directions_bike</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-dynamic-form (saveDynamicFormEvent)="saveData($event, performanceEvent)" [questions]="performanceQuestions" [formName]="performanceEvent"></app-dynamic-form>

            <mat-action-row>
                <button mat-raised-button color="accent" (click)="nextStep()">Dalej</button>
            </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="showChamber" [expanded]="step === chamberStep" (opened)="setStep(chamberStep)" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Komora cieplna
                </mat-panel-title>
                <mat-panel-description>
                    Badanie tolerancji wysokiej temperatury i wilgotności
                    <mat-icon>hot_tub</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-dynamic-form (saveDynamicFormEvent)="saveData($event, chamberEvent)" [questions]="chamberQuestions" [formName]="chamberEvent"></app-dynamic-form>

            <mat-action-row>
                <button mat-raised-button color="accent" (click)="nextStep()">Dalej</button>
            </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="showAudio" [expanded]="step === audioStep" (opened)="setStep(audioStep)" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Audiometr
                </mat-panel-title>
                <mat-panel-description>
                    Badanie audiometryczne
                    <mat-icon>hearing</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-dynamic-form (saveDynamicFormEvent)="saveData($event, audioEvent)" [questions]="audioQuestions" [formName]="audioEvent"></app-dynamic-form>

            <mat-action-row>
                <button mat-raised-button color="accent" (click)="nextStep()">Dalej</button>
            </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="showXray" [expanded]="step === xrayStep" (opened)="setStep(xrayStep)" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    RTG
                </mat-panel-title>
                <mat-panel-description>
                    Badanie radiologiczne klatki piersiowej
                    <mat-icon>assignment_ind</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-dynamic-form (saveDynamicFormEvent)="saveData($event, xrayEvent)" [questions]="xrayQuestions" [formName]="xrayEvent"></app-dynamic-form>

            <mat-action-row>
                <button mat-raised-button color="accent" (click)="nextStep()">Dalej</button>
            </mat-action-row>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="showPsychologist" [expanded]="step === psychologistStep" (opened)="setStep(psychologistStep)" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Psycholog
                </mat-panel-title>
                <mat-panel-description>
                    Badanie psychologiczne
                    <mat-icon>sentiment_satisfied_alt</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-dynamic-form (saveDynamicFormEvent)="saveData($event, psychologistEvent)" [questions]="psychologistQuestions"></app-dynamic-form>

            <mat-action-row>
                <button mat-raised-button color="primary" (click)="nextStep()">Dalej</button>
            </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>
</div>