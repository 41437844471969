import { Component, OnInit, ViewChild } from '@angular/core';
import { Appointment } from 'src/app/core/models/appointment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppointmentService } from '../services/appointment.service';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/core/services/global.service';
import { ExaminationService } from 'src/app/examination/services/examination.service';

@Component({
  templateUrl: './today-list.component.html'
})
export class AppointmentTodayListComponent implements OnInit {

  loading = true;
  displayedColumns: string[] = [
    "dailyNumber",
    'patientName',
    'patientRecordNumber',
    'examinationGroupName',
    'actions'];

  filterValue = '';
  dataSource: MatTableDataSource<Appointment>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private appointmentService: AppointmentService,
    private finalExaminationService: ExaminationService,
    protected globalService: GlobalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  appointmentDetails(row: Appointment) {
    let route = '/appointments/details';
    this.router.navigate([route], { queryParams: { id: row.id } });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadData() {
    console.log('in loading');
    this.loading = true;
    this.appointmentService.getResources('/api/todayappointments').pipe(
    ).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }

  print(row: Appointment) {
    this.appointmentService
      .downloadPDF("/api/appointments/" + row.id + "/pdfblob")
      .pipe()
      .subscribe((res) => {
        const byteArray = new Uint8Array(
          atob(res.blob)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      },
        (err) => { this.globalService.showError(err.error.translatedMessage); });
  }

  printFinal(row: Appointment) {
    this.finalExaminationService
      .downloadPDF("/api/appointments/" + row.id + "/decisionpdfblob")
      .pipe()
      .subscribe((res) => {
        const byteArray = new Uint8Array(
          atob(res.blob)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        var blob = new Blob([byteArray], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      },
        (err) => { this.globalService.showError(err.error.translatedMessage); });
  }
}
