<div class="wingsmed-forbidden-error" *ngIf="error === true">
    {{errorMessage}}
</div>
<div class="row wingsmed-row" *ngIf="loading === false">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <p> W wyniku przeprowadzonego w dniu
            <mat-form-field appearance="fill">
                <input matInput [matDatepicker]="dp1" formControlName="examinationDate" disabled>
                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                <mat-datepicker #dp1 disabled="false"></mat-datepicker>
            </mat-form-field> badania {{dataSource.examinationGroup | lowercase}}go
        </p>
        <p> Pana {{dataSource.fullName}} </p>
        <p>ur. {{dataSource.birthDate | date:'dd.MM.yyyy' }} w {{ dataSource?.birthPlace }} </p>
        <p> zatrudnionego w zakładzie pracy {{ dataSource.factory }} </p>
        <p>stwierdzam &nbsp;
            <input type="radio" id="no" value="no" formControlName="result"
                (change)="onResultChange($event.target.value)">
            <label for="no">&nbsp;brak</label>&nbsp;&nbsp;
            <input type="radio" id="existing" value="existing" formControlName="result"
                (change)="onResultChange($event.target.value)">
            <label for="existing">&nbsp;istnienie</label> przeciwwskazań zdrowotnych do słuzby w ratownictwie górniczym.
        </p>
        <div *ngIf="result === true">
            <div style="float:left;">Data ponownego badania lekarskiego</div>
            <div style="float:left;">
                <input type="radio" id="dateType" value="date" formControlName="resultOK"
                    (change)="onResultOKChange($event.target.value)">
                <mat-form-field appearance="fill">

                    <input matInput [matDatepicker]="dp2" formControlName="nextDate" disabled>
                    <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                    <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                </mat-form-field>
                <br>
                <input type="radio" id="aftermonth" value="za miesiąc" formControlName="resultOK"
                    (change)="onResultOKChange($event.target.value)">
                <label for="aftermonth">&nbsp;za miesiąc</label>&nbsp;&nbsp;
                <br>
                <input type="radio" id="after2months" value="za 2 miesiące" formControlName="resultOK"
                    (change)="onResultOKChange($event.target.value)">
                <label for="after2months">&nbsp;za 2 miesiące</label>&nbsp;&nbsp;
                <br>
                <input type="radio" id="after3months" value="za 3 miesiące" formControlName="resultOK"
                    (change)="onResultOKChange($event.target.value)">
                <label for="after3months">&nbsp;za 3 miesiące</label>&nbsp;&nbsp;
                <br>
                <input type="radio" id="after6months" value="za 6 miesięcy" formControlName="resultOK"
                    (change)="onResultOKChange($event.target.value)">
                <label for="after6months">&nbsp;za 6 miesięcy</label>&nbsp;&nbsp;
                <br>
                <input type="radio" id="after12months" value="za rok" formControlName="resultOK"
                    (change)="onResultOKChange($event.target.value)">
                <label for="after12months">&nbsp;za rok</label>&nbsp;&nbsp;
            </div>
            <div style="clear:both;"></div>
        </div>
        <p *ngIf="result === false">
            Data ponownego badania lekarskiego
            <mat-form-field appearance="fill">
                <input matInput formControlName="nextDateReason" value="w wyznaczonym terminie">
            </mat-form-field>
        </p>
        <div class="form-row ">
            <button mat-raised-button color="primary" type="submit ">Zapisz</button>
        </div>
    </form>
</div>