import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { DeviceRoutingModule } from './device-routing.module';
import { DeviceMaintenanceComponent } from './components/maintenance.component';
import { QrCodeModule } from 'ng-qrcode';


@NgModule({
  declarations: [DeviceMaintenanceComponent],
  imports: [
    SharedModule,
    DeviceRoutingModule,
    QrCodeModule
  ]
})
export class DeviceModule { }
