<div class="row wingsmed-row">
    <div class="col-md-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <mat-icon class="mr-2">add_to_queue</mat-icon>
                    <span class="page-title">Wszystkie wizyty</span>
                    <div class="loading-shade" *ngIf="loading">
                        <mat-spinner *ngIf="loading" [diameter]="24"></mat-spinner>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="wingsapp-card-content" *ngIf="!loading">
                    <mat-form-field>
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtr">
                    </mat-form-field>
                    <div class="mat-elevation-z4">
                        <table mat-table [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="dailyNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nr dzienny </th>
                                <td mat-cell *matCellDef="let row"> {{row.dailyNumber}} </td>
                            </ng-container>
                            <ng-container matColumnDef="patientName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nazwisko i imię </th>
                                <td mat-cell *matCellDef="let row"> {{row.patientName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="patientRecordNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nr kartoteki </th>
                                <td mat-cell *matCellDef="let row"> {{row.patientRecordNumber}} </td>
                            </ng-container>
                            <ng-container matColumnDef="date">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
                                <td mat-cell *matCellDef="let row"> {{row.date | date:'dd.MM.yyyy'}}</td>
                            </ng-container>
                            <ng-container matColumnDef="examinationGroupName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Grupa badań </th>
                                <td mat-cell *matCellDef="let row"> {{row.examinationGroupName}} </td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef> Akcja </th>
                                <td mat-cell *matCellDef="let row">
                                    <button mat-raised-button color="accent" (click)="appointmentDetails(row)">Szczegóły</button>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns "></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[25, 50, 100] " showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>