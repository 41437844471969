import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseQuestion } from './question-base';
import { QuestionControlService } from './question-control';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [ QuestionControlService ]
})
export class DynamicFormComponent implements OnInit {

  @Input() questions: BaseQuestion[] | null = [];
  @Input() formName: string | null = "";
  @Output() saveDynamicFormEvent = new EventEmitter<JSON>();
  form!: UntypedFormGroup;
  payLoad;

  constructor(private qcs: QuestionControlService) {}

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.questions as BaseQuestion[]);
  }

  onSubmit() {
    let jsonObject = {};  
    this.questions.forEach(val => jsonObject[val.key] = val.value);  
    this.payLoad = JSON.stringify(jsonObject);  
  
    this.saveDynamicFormEvent.emit(this.payLoad);
  }
}
